import React from "react";

interface Props {
  text: string;
  center?: boolean;
}

function Header(props: Props) {
  const temp = props.center ? " text-center" : "";
  return (
    <div className={"sm:text-4xl text-2xl font-bold" + temp}>{props.text}</div>
  );
}

export default Header;
