import { useEffect } from "react";
import MetabolicHealthIntroView from "../views/MetabolicHealthIntroView";
import MetabolicListView from "../views/MetabolicListView";
import MetabolicGraphView from "../views/MetabolicGraphView";
import MetabolicCurveView from "../views/MetabolicCurveView";
import Topbar from "../components/Topbar";
import FootView from "../views/FootView";
import { Helmet } from "react-helmet";
import * as React from "react";

function Metabolic(props: any) {
  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <></>;

  return (
    <>
      <Helmet>
        <title>{"Saluto"}</title>
      </Helmet>
      <Topbar pathName={props.location.pathname}></Topbar>
      <MetabolicHealthIntroView pathName={props.location.pathname} />
      <MetabolicListView pathName={props.location.pathname}></MetabolicListView>
      <MetabolicGraphView
        pathName={props.location.pathname}
      ></MetabolicGraphView>
      <MetabolicCurveView
        pathName={props.location.pathname}
      ></MetabolicCurveView>
      <FootView pathName={props.location.pathname}></FootView>
    </>
  );
}

export default Metabolic;
